import React, {useEffect, useState} from 'react'
import {documentToHtmlString} from '@contentful/rich-text-html-renderer'
import parse from 'html-react-parser'
import {useContentful} from 'react-contentful'
import {Link, useLocation} from 'react-router-dom'
import * as Scroll from 'react-scroll'
import AppIcon from '../../components/AppIcon'
import AppImage from '../../components/AppImage'
import Button from '../../components/Button'
import Gallery from '../../components/Gallery'
import ProfileCard from '../../components/ProfileCard'
import ReviewList from '../../components/ReviewList'
import RichText from '../../components/RichText'
import Tab, {TabGroup} from '../../components/Tab'
import Text from '../../components/Text'
import {ContentModel} from '../../services/contentful'
import {useFirebase} from '../../services/firebase'
import {filterTags} from '../../services/product'
import {useHashScroll} from '../../services/url'
import NotFound from '../NotFound'
import Shell from '../Shell'
import ShopModule from '../ShopModule'
import './styles.scss'
import TheoristInfo from './scenes/TheoristInfo'

export default function Theorist() {
  const {hash, pathname} = useLocation()
  const paths = pathname.split('/')
  const theoristHandle = paths[1]
  const [activeTab, setActiveTab] = useState('shop')
  const [theorist, setTheorist] = useState(null)
  const [loading, setLoading] = useState(true)
  const [fetched, setFetched] = useState(false)
  const [error, setError] = useState(null)
  const {
    loading: theoristLoading,
    fetched: theoristFetched,
    error: theoristError,
    data: theoristData,
  } = useContentful({
    contentType: 'theorist',
    query: {'fields.theoristHandle': theoristHandle},
  })
  const {
    loading: pageLoading,
    fetched: pageFetched,
    error: pageError,
    data: pageData,
  } = useContentful({
    contentType: 'page',
    query: {'fields.link': '/theorist'},
  })
  const {theorist: theoristUser} = useFirebase()
  useEffect(() => {
    setLoading(theoristLoading || pageLoading)
  }, [theoristLoading, pageLoading])
  useEffect(() => {
    setFetched(theoristFetched && pageFetched)
  }, [theoristFetched, pageFetched])
  useEffect(() => {
    setError(theoristError || pageError)
  }, [theoristError, pageError])
  useEffect(() => {
    if (error || !theoristData || theoristData.items.length === 0) return
    const fetchedTheorist = ContentModel(theoristData.items[0])
    if (fetchedTheorist.tags.includes('hidden')) return
    setTheorist(fetchedTheorist)
  }, [theoristData, error])
  useHashScroll(hash, [theorist], -64)
  if (loading || !fetched) return <Shell className="Theorist" />
  if (!theorist) return <NotFound />
  const skillTags = filterTags(theorist.tags, 'skill')
  const modules = ContentModel(pageData.items[0] || {}).modules || []
  const profilePic = !theorist.profilePicture ? null : `${theorist.profilePicture.file.url}?w=256&h=256`
  const isEmpty = modules.length < 1
  const banner = theoristUser && theoristUser.handle === theorist.theoristHandle ? <>
    This is the live view of your page. <Link className="Theorist__bannerLink" to="/dash/settings">Edit</Link>
  </> : null
  const overallMetrics = theorist.metrics?.overall ?? {}
  return <Shell
    className="Theorist"
    title={theorist.name}
    banner={banner}
    noSticky>
    <div className="Theorist__gallery">
      <Gallery>
        {(theorist.featuredMedia || []).map(media => <AppImage
          key={media.id}
          src={`${media.file.url}?w=1024&h=1024`} />)}
      </Gallery>
      {!theorist.instagramHandle ? null : <Button
        className="Theorist__viewInstagram"
        styleVariant="tertiary"
        href={`https://instagram.com/${theorist.instagramHandle}`}
        rel="noopener noreferrer"
        target="_blank"
        slim>
        <AppIcon icon="instagram" />
        <Text
          className="Theorist__viewInstagramText"
          styleVariant="body3">
          View Instagram
        </Text>
      </Button>}
    </div>
    <AppImage className="Theorist__profilePicture" src={profilePic} circular bordered elevated />
    <TheoristInfo
      name={theorist.name}
      location={theorist.locationText}
      elite={overallMetrics.elite}
      orderCount={overallMetrics.orderCount}
      reviewCount={overallMetrics.reviewCount}
      reviewTotal={overallMetrics.reviewTotal}
      stringingSince={theorist.stringingSince}
      pinnedNote={theorist.pinnedNote} />
    <div className="Theorist__tabs">
      <TabGroup>
        {['Shop', 'Reviews', 'About'].map((tab, index) => <Scroll.Link
          key={`tab ${index}`}
          to={tab.toLowerCase()}
          offset={index === 0 ? -96 : -40}
          duration={300}
          onSetActive={to => setActiveTab(to)}
          smooth spy>
          <Tab selected={activeTab === tab.toLowerCase()}>{tab}</Tab>
        </Scroll.Link>)}
      </TabGroup>
    </div>
    <Scroll.Element name="shop">
      {!isEmpty ? null : <Text className="Theorist__emptyMessage">No listings, yet.</Text>}
      {modules.filter(mod => !(mod.tags || []).includes('hidden'))
        .map(mod => <ShopModule
          key={mod.id}
          {...mod}
          itemExtras={{
            theoristId: theorist.id,
            theoristName: theorist.name,
          }}
          query={mod.source === 'shopify' && [
            ([...mod.tags, theorist.theoristHandle].map(tag => `tag:${tag}`).join(' ')),
            ...skillTags.map(skillTag => [...mod.tags, skillTag].map(tag => `tag:${tag}`).join(' ')),
          ].map(group => `(${group})`).join(' OR ')} />)}
    </Scroll.Element>
    <Scroll.Element
      className="Theorist__section"
      name="reviews">
      <ReviewList
        {...(theorist.metrics?.overall ?? {})}
        theoristHandle={theorist.theoristHandle}
        editorProps={{theoristName: theorist.name}} />
    </Scroll.Element>
    <Scroll.Element
      className="Theorist__section"
      name="about">
      <Text styleVariant="heading2">Meet your Theorist</Text>
      <div className="Theorist__about">
        <ProfileCard
          className="Theorist__profileCard"
          image={profilePic}
          title={theorist.name}
          subtitle={theorist.locationText}
          variant="large"
          elite={overallMetrics.elite}
          orderCount={overallMetrics.orderCount}
          reviewCount={overallMetrics.reviewCount}
          reviewTotal={overallMetrics.reviewTotal}
          stringingSince={theorist.stringingSince} />
        <div className="Theorist__aboutInfo">
          <RichText>{parse(documentToHtmlString(theorist.about))}</RichText>
          <Button
            className="Theorist__contact"
            styleVariant="tertiary"
            href={`mailto:${theorist.email}`}>
            Contact theorist
          </Button>
        </div>
      </div>
    </Scroll.Element>
  </Shell>
}
